import React from "react";
import { Layout } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, SearchOutlined } from '@ant-design/icons';
import Logo from './Logo';
import utils from "../../utils";
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from "../../constants";
import { onMobileNavToggle, selectTheme, toggleCollapsedNav } from "../../redux/themeSlice";
import { useAppDispatch } from "../../redux/store";
import NavNotification from "./NavNotification";
import NavLanguage from "./NavLanguage";
import { HeaderNavProps } from "../../model/ui-components";
import NavProfile from "./NavProfile";

const { Header } = Layout;

export const HeaderNav = (props: HeaderNavProps) => {
  const { isMobile, navCollapsed, navType, headerNavColor, currentTheme, mobileNav, locale } = props;
  const dispatchReduxToolkit = useAppDispatch();

  const onToggle = () => {
    if (!isMobile) {
      dispatchReduxToolkit(toggleCollapsedNav(!navCollapsed));
    } else {
      dispatchReduxToolkit(onMobileNavToggle(!mobileNav));
    }
  }

  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff')
    }
    return utils.getColorContrast(headerNavColor)
  }
  const navMode = mode()
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return '0px'
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`
    } else {
      return `${SIDE_NAV_WIDTH}px`
    }
  }

  return (
    <Header className={`app-header ${navMode}`} style={{ backgroundColor: headerNavColor }}>
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={navMode} navCollapsed={navCollapsed} navType={navType} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {
                isNavTop && !isMobile ?
                  null
                  :
                  <li className="ant-menu-item ant-menu-item-only-child" onClick={() => { onToggle() }}>
                    {navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon" /> : <MenuFoldOutlined className="nav-icon" />}
                  </li>
              }
            </ul>
          </div>
          <div className="nav-right">
            {/* <NavNotification /> */}
            <NavLanguage locale={locale} />
            <NavProfile />
            {/* <NavPanel direction={direction} /> */}
          </div>
          {/* <NavSearch active={searchActive} close={onSearchClose}/> */}
        </div>
      </div>
    </Header>
  )
}

export default HeaderNav;