import React from 'react';
import './App.css';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { Route, Switch } from 'react-router-dom';
import Views from './views';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from "react-redux";
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import store from './redux/store';
import { initialState } from './redux/themeSlice';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

let persistor = persistStore(store);

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeSwitcherProvider themeMap={themes} defaultTheme={initialState.currentTheme} insertionPoint="styles-insertion-point">
            <Router>
              <Switch>
                <Route path="/" component={Views} />
              </Switch>
            </Router>
          </ThemeSwitcherProvider>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
