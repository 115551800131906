import React from 'react';
import { NAV_TYPE_TOP } from '../../constants';
import { TopNavProps } from '../../model/ui-components';
import utils from '../../utils';
import MenuContent from './MenuContent';

export const TopNav = ({ topNavColor, localization = true }: TopNavProps) => {
	const props = { topNavColor, localization }
	return (
		<div className={`top-nav ${utils.getColorContrast(topNavColor)}`} style={{ backgroundColor: topNavColor }}>
			<div className="top-nav-wrapper">
				<MenuContent
					type={NAV_TYPE_TOP}
					{...props}
				/>
			</div>
		</div>
	)
}

export default TopNav;
