import { Switch, Route, BrowserRouter, useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import Loading from "../../components/shared-components/Loading";
import AuthViews from "../../views/auth-views";
import { useSelector } from "react-redux";
import { selectUserIsLogin } from "../../redux/userSlice";
import { APP_PREFIX_PATH } from "../../configs/AppConfig";
import Theme from "../../model/Theme";
import { NavLanguageProps } from "../../model/ui-components";

const AuthLayout = ({ locale }: NavLanguageProps) => {
  const { status } = useThemeSwitcher();
  const isLoggedIn = useSelector(selectUserIsLogin);
  let history = useHistory();

  useEffect(() => {
    if (isLoggedIn) history.push(APP_PREFIX_PATH);
  }, [isLoggedIn]);

  if (status === "loading") {
    return <Loading cover="page" />;
  }

  return (
    <div className="auth-container">
      <BrowserRouter>
        <Switch>
          <Route path="" component={() => <AuthViews locale={locale}/>} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default AuthLayout;
