import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ConfigProvider } from 'antd';
import AuthLayout from "../layouts/auth-layout";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "../configs/AppConfig";
import AuthRoute from "./auth-views/components/AuthRoute";
import { useSelector } from "react-redux";
import { selecLocale, selectTheme } from "../redux/themeSlice";
import { IntlProvider } from "react-intl";
import AppLayout from "../layouts/app-layout";
import fetchTranslations from "../lang";

export const Views = (props: any) => {
  const [localeData, setLocaleData] = useState<any>(null);
  const { navCollapsed, navType, headerNavColor, currentTheme, mobileNav, locale, topNavColor, sideNavTheme, direction } = useSelector(selectTheme);

  const localeRedux = useSelector(selecLocale);

  useEffect(() => {
    const fetchDataTranslations = async () => {
      const response: any = await fetchTranslations();
      setLocaleData(response[locale]);
    }
    fetchDataTranslations();
  }, [localeRedux]);


  const { location } = props;

  return (
    <IntlProvider
      locale={localeData?.locale}
      messages={localeData?.messages}>
      <ConfigProvider locale={localeData?.antd} >
        <Switch>
          <Route exact path="/">
            <Redirect to={APP_PREFIX_PATH} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout locale={locale}/>
          </Route>
          <AuthRoute path={APP_PREFIX_PATH}>
            <AppLayout
              location={location}
              navCollapsed={navCollapsed}
              sideNavTheme={sideNavTheme}
              locale={locale}
              navType={navType}
              topNavColor={topNavColor}
              headerNavColor={headerNavColor}
              mobileNav={mobileNav}
              currentTheme={currentTheme}
              direction={direction} />
          </AuthRoute>
        </Switch>
      </ConfigProvider >
    </IntlProvider>
  )
}

export default Views;