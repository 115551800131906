import React, { Component } from 'react'

interface IconvProps {
	type: any;
	className?: string;
}

export const Icon = ({ type, className }: IconvProps) => {
	return (
		<>{React.createElement(type, { className: className })}</>
	)
}

export default Icon;