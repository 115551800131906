import { auth } from "../configs/FireBaseConf";
import { sendPasswordResetEmail, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getUserDetail } from "./firestores/admins";

export const loginUser = async (email: string, password: string) => {
    return await signInWithEmailAndPassword(auth, email, password);
}

export const getProfile = async () => {
    const user = auth.currentUser;
    if (user !== null) {
        const userDetail = await getUserDetail(user.uid);

        if (userDetail.exists())
            return {
                "name": userDetail.data()?.name,
                "email": user.email,
                "image": userDetail.data()?.image,
                "uid": user.uid
            }
        throw new Error('Cannot load profile')
    }
    throw new Error('Username/password is wrong')
}

export const sendEmailToResetPassword = async (email: string) => {
    return await sendPasswordResetEmail(auth, email);
}

export const logoutUser = async () => {
    return await signOut(auth);
}

export const authChangeState = (handler: any) => {
    return onAuthStateChanged(auth, (user) => {
        handler(user);
    });
}
