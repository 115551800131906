// import { fetchingLangFromRMC } from '../configs/FireBaseConf';
import antdEnUS from 'antd/es/locale/en_US';
import antdViVN from 'antd/es/locale/vi_VN';
import antdLoLA from 'antd/es/locale/default';
import enMsg from './en.json'
import viMsg from './vi.json'
import loMsg from './lo.json'

const fetchTranslations = async () => {
    try {
        // const res = await fetchingLangFromRMC();
        return {
            en: {
                antd: antdEnUS,
                locale: 'en-US',
                messages: {
                    ...enMsg
                }
            },
            vi: {
                antd: antdViVN,
                locale: 'vi-VN',
                messages: {
                    ...viMsg
                }
            },
            lo: {
                antd: antdLoLA,
                locale: 'lo-LA',
                messages: {
                    ...loMsg
                }
            }
        }
    } catch (error) {
        console.error('Error fetching translations:', error);
        return {};
    }
}

export default fetchTranslations;