import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getRemoteConfig } from "firebase/remote-config";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { REMOTE_CONFIG_CACHE } from "./AppConfig";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);

export const auth = getAuth(app);

export const db = getFirestore(app);

export const storage = getStorage(app);

export const functions = getFunctions(app, 'asia-southeast1');

export const remoteConfig = getRemoteConfig(app);

remoteConfig.settings.minimumFetchIntervalMillis = REMOTE_CONFIG_CACHE;

export const fetchingLangFromRMC = async () => {
    const isFetching = await fetchAndActivate(remoteConfig);

    return {
        en: JSON.parse(getValue(remoteConfig, "en").asString()),
        vi: JSON.parse(getValue(remoteConfig, "vi").asString()),
        lo: JSON.parse(getValue(remoteConfig, "lo").asString())
    }
}

