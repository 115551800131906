import React from "react";
import { Menu, Dropdown, Avatar, DropDownProps } from "antd";
import {
  EditOutlined,
  SettingOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import Icon from "../shared-components/Icon";
import { logoutUser } from "../../services/FirebaseAuthService";
import { useSelector } from "react-redux";
import { selectuser } from "../../redux/userSlice";

const menuItem = [
  {
    title: "Edit Profile",
    icon: EditOutlined,
    path: "/"
  },

  {
    title: "Account Setting",
    icon: SettingOutlined,
    path: "/"
  }
]

export const NavProfile = () => {

  const currentUser = useSelector(selectuser);

  const profileImg = currentUser.image;
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={currentUser.image} />
          <div className="pl-3">
            <h4 className="mb-0">{currentUser.name}</h4>
            <span className="text-muted">Administrator</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i} disabled>
                <a href={el.path}>
                  <Icon type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={e => logoutUser()}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  const navProfileDropDownProps: DropDownProps = {
    placement: "bottomRight",
    overlay: profileMenu,
    trigger: ['click']
  }

  return (
    <Dropdown {...navProfileDropDownProps}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default NavProfile;
