import React from "react";
import { CheckOutlined, GlobalOutlined } from '@ant-design/icons';
import { Menu, Dropdown, DropDownProps } from "antd";
import { useAppDispatch } from "../../redux/store";
import { onLocaleChange } from "../../redux/themeSlice";
import { NavLanguageProps } from "../../model/ui-components";
import { useIntl } from "react-intl";

const lang = [
	{
		"langName": "English",
		"langId": "en",
		"icon": "us",
		"lang": "En"
	},
	{
		"langName": "Tiếng Việt",
		"langId": "vi",
		"icon": "vi",
		"lang": "Vi"
	},
	// {
	// 	"langName": "ພາສາລາວ",
	// 	"langId": "lo",
	// 	"icon": "la",
	// 	"lang": "lo"
	// }
]

export const NavLanguage = ({ locale, hasIcon = true }: NavLanguageProps) => {
	const intl = useIntl()

	const dispatchReduxToolkit = useAppDispatch();

	const languageOption = (
		<Menu>
			{
				lang.map((elm, i) => {
					return (
						<Menu.Item
							key={elm.langName}
							className={locale === elm.langId ? 'ant-dropdown-menu-item-active' : ''}
							onClick={() => dispatchReduxToolkit(onLocaleChange(elm.langId))}
						>
							<span className="d-flex justify-content-between align-items-center">
								<div>
									<img style={{ maxWidth: '20px' }} src={`/img/flags/${elm.icon}.png`} alt={elm.langName} />
									<span className="font-weight-normal ml-2">{elm.langName}</span>
								</div>
								{locale === elm.langId ? <CheckOutlined className="text-success" /> : null}
							</span>
						</Menu.Item>
					)
				})
			}
		</Menu>
	)

	const navLanguageDropDownProps: DropDownProps = {
		placement: "bottomRight",
		overlay: languageOption,
		trigger: ['click']
	}

	return (
		<Dropdown {...navLanguageDropDownProps}>
			<Menu mode="horizontal" style={{backgroundColor: "transparent", borderBottom: "unset"}}>
				<Menu.Item key="language" className={`${hasIcon ? "" : "login-languages__wrapper"}`} >
					<a href="#/" onClick={e => e.preventDefault()} >
						{
							hasIcon ?
							<GlobalOutlined className="nav-icon mr-0" /> :
							<div className="login-languages__text">{`${intl.messages['login.languages']}`}</div>
						}
					</a>
				</Menu.Item>
			</Menu>
		</Dropdown>
	)
}

export default NavLanguage;
