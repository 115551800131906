import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
    persistReducer,
} from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session'
import { useDispatch } from 'react-redux';
import userReducer from '../redux/userSlice';
import themeReducer from '../redux/themeSlice';

const rootReducer = combineReducers({
    user: userReducer,
    theme: themeReducer
});

const persistConfig = {
    key: 'rootDrFam',
    version: 1,
    storage: sessionStorage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export type RootState = ReturnType<typeof rootReducer>;
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {

        return getDefaultMiddleware();
    }
});
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>()
export default store;
