import React from 'react';
import SideNav from '../../components/layout-components/SideNav';
import { Layout, Grid } from "antd";
import { DIR_LTR, DIR_RTL, NAV_TYPE_SIDE, NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from '../../constants';
import utils from '../../utils';
import navigationConfig from '../../configs/NavigationConfig';
import HeaderNav from '../../components/layout-components/HeaderNav';
import Theme from '../../model/Theme';
import TopNav from '../../components/layout-components/TopNav';
// import Footer from '../../components/layout-components/Footer';
import MobileNav from '../../components/layout-components/MobileNav';
import AppViews from '../../views/app-views';
// import PageHeader from '../../components/layout-components/PageHeader';

const { Content } = Layout;
const { useBreakpoint } = Grid;

interface AppLayoutProps extends Theme {
  location: any;
}

export const AppLayout = ({ navCollapsed, navType, location, direction, locale, sideNavTheme, topNavColor, headerNavColor, mobileNav, currentTheme }: AppLayoutProps) => {
  const currentRouteInfo: any = utils.getRouteInfo(navigationConfig, location.pathname)
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes('lg')
  const isNavSide = navType === NAV_TYPE_SIDE
  const isNavTop = navType === NAV_TYPE_TOP
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
  }

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return { paddingLeft: getLayoutGutter() }
    }
    if (direction === DIR_RTL) {
      return { paddingRight: getLayoutGutter() }
    }
    return { paddingLeft: getLayoutGutter() }
  }

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} navCollapsed={navCollapsed} locale={locale} sideNavTheme={sideNavTheme} navType={navType} topNavColor={topNavColor} headerNavColor={headerNavColor} mobileNav={mobileNav} currentTheme={currentTheme} direction={direction} />
      {(isNavTop && !isMobile) ? <TopNav topNavColor={topNavColor} routeInfo={currentRouteInfo} /> : null}
      <Layout className="app-container">
        {(isNavSide && !isMobile) ? <SideNav routeInfo={currentRouteInfo} navCollapsed={navCollapsed} hideGroupTitle={undefined} /> : null}
        <Layout className="app-layout" style={getLayoutDirectionGutter()}>
          <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
            {/* <PageHeader {...pageHeader} /> */}
            <Content>
              <AppViews />
            </Content>
          </div>
          {/* <Footer /> */}
        </Layout>
      </Layout>
      {isMobile && <MobileNav hideGroupTitle={undefined} routeInfo={currentRouteInfo} sideNavTheme={sideNavTheme} mobileNav={mobileNav} navType={navType} headerNavColor={headerNavColor} currentTheme={currentTheme} />}
    </Layout>
  )
}

export default React.memo(AppLayout);