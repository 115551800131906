import React from "react";
import { Layout } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import MenuContent from './MenuContent'
import { NAV_TYPE_SIDE, SIDE_NAV_WIDTH } from "../../constants";
import { SideNavProps } from "../../model/ui-components";

const { Sider } = Layout;

export const SideNav = ({ navCollapsed, routeInfo, hideGroupTitle, localization = true }: SideNavProps) => {
  const props = { routeInfo, hideGroupTitle, localization }
  return (
    <Sider
      className={'side-nav'}
      width={SIDE_NAV_WIDTH}
      collapsed={navCollapsed}
    >
      <Scrollbars autoHide>
        <MenuContent
          type={NAV_TYPE_SIDE}
          {...props}
        />
      </Scrollbars>
    </Sider>
  )
}

export default SideNav;
