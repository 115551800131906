import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../configs/FireBaseConf";

export const getUserDetail = async (uuid: string) => {
  const docRef = doc(db, "Admins", uuid);
  return getDoc(docRef);
};

export const getUserDataDetail = async (id: string) => {
  const docRef = doc(db, "Admins", id);
  const data = await getDoc(docRef);
  if (data.exists())
    return { id: data.id, ...data.data() }
  return null;
};