import React from 'react';
import { Grid } from 'antd';
import utils from '../../utils';
import { APP_NAME } from '../../configs/AppConfig';
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from '../../constants';
import { LogoProps } from '../../model/ui-components';

const { useBreakpoint } = Grid;

const getLogoWidthGutter = (navCollapsed: boolean, navType: string, isMobile: boolean, mobileLogo: any) => {
  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  if (isMobile && !mobileLogo) {
    return 0
  }
  if (isNavTop) {
    return 'auto'
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`
  } else {
    return `${SIDE_NAV_WIDTH}px`
  }
}

const getLogo = (navCollapsed: boolean, logoType: string) => {
  if (logoType === 'light') {
    if (navCollapsed) {
      return '/img/logo-sm-white.png'
    }
    return '/img/others/logo-capture.png'
  }

  if (navCollapsed) {
    return '/img/logo-drs-fan-sm.png'
  }
  return '/img/others/logo-capture.png'
}

const getLogoDisplay = (isMobile: boolean, mobileLogo: any) => {
  if (isMobile && !mobileLogo) {
    return 'd-none'
  } else {
    return 'logo'
  }
}

export const Logo = ({ mobileLogo, navCollapsed, navType, logoType }: LogoProps) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
  return (
    <div
      className={getLogoDisplay(isMobile, mobileLogo)}
      style={{ width: `${getLogoWidthGutter(navCollapsed, navType, isMobile, mobileLogo)}`, justifyContent: `${!isMobile ? "center" : "unset"}` }}>
      <img style={{ height: "60px" }} src={getLogo(navCollapsed, logoType)} alt={`${APP_NAME} logo`} />
    </div>
  )
}

export default Logo;
