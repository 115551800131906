import {
  BarChartOutlined,
  SmileOutlined,
  ApiOutlined,
  ShoppingOutlined,
  ShoppingCartOutlined,
  SettingOutlined,
  WindowsOutlined,
  ProfileOutlined,
  MedicineBoxOutlined,
  SnippetsOutlined,
  UserOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "./AppConfig";

const managementNavTree = [
  {
    key: "management",
    path: `${APP_PREFIX_PATH}/management`,
    title: "sidenav.management",
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "management-document",
        path: `${APP_PREFIX_PATH}/management/document`,
        title: "sidenav.management.document",
        icon: WindowsOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "management-news",
        path: `${APP_PREFIX_PATH}/management/news`,
        title: "sidenav.management.news",
        icon: SnippetsOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "management-calendar",
        path: `${APP_PREFIX_PATH}/management/calendar`,
        title: "sidenav.management.calendar",
        icon: CalendarOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "management-ecommerce",
        path: `${APP_PREFIX_PATH}/management/ecommerce`,
        title: "sidenav.management.ecommerce",
        icon: ShoppingCartOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "management-ecommerce-list",
            path: `${APP_PREFIX_PATH}/management/ecommerce/product-list`,
            title: "sidenav.management.products.list",
            icon: ShoppingOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "management-ecommerce-orders",
            path: `${APP_PREFIX_PATH}/management/ecommerce/orders`,
            title: "sidenav.management.orders",
            icon: BarChartOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "management-ecommerce-setting",
            path: `${APP_PREFIX_PATH}/management/ecommerce/setting`,
            title: "sidenav.management.setting",
            icon: SettingOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const userNavTree = [
  {
    key: "users",
    path: `${APP_PREFIX_PATH}/users`,
    title: "sidenav.users",
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "users-doctor",
        path: `${APP_PREFIX_PATH}/users/doctor`,
        title: "sidenav.users.doctor",
        icon: MedicineBoxOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "users-doctor-list",
            path: `${APP_PREFIX_PATH}/users/doctor/list`,
            title: "sidenav.users.doctor.list",
            icon: ProfileOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "users-doctor-mapping",
            path: `${APP_PREFIX_PATH}/users/doctor/mapping`,
            title: "sidenav.users.doctor.mapping",
            icon: ApiOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "users-patient",
        path: `${APP_PREFIX_PATH}/patient/add`,
        title: "sidenav.patient",
        icon: SmileOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "users-patient-list",
            path: `${APP_PREFIX_PATH}/users/patient/list`,
            title: "sidenav.users.patient.list",
            icon: ProfileOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const navigationConfig = [...managementNavTree, ...userNavTree];

export default navigationConfig;
